<template>
  <div class="v-stack h-stretch gap-3">
    <a class="heading-title-2">Overview</a>
    <div class="card light">
      <div class="content">
        <div class="v-stack gap-3">
          <a>Total overview</a>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Hours:</div>
            <div class="text-left">{{ hoursTotal }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Unsigned:</div>
            <div class="text-left">{{ unsignedTotal }} (items)</div>
          </div>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Revenue:</div>
            <div class="text-left">{{ format.currency(revenueTotal) }} Kč</div>
          </div>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Advance:</div>
            <div class="text-left">{{ format.currency(paidTotal) }} Kč</div>
          </div>
          <div class="pane-horizontal gap-3">
            <div class="text-right">Balance:</div>
            <div class="text-left">{{ format.currency(balance) }} Kč</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import utils from "@/utils.js";
const moment = require("moment");

export default {
  data() {
    return {
      filter: "",
      workdays: [],
      oneTimePay: [],
      year: moment().year(),
      month: moment().month(),
      signed: true,
      unsigned: true,
      canceled: true,
      format: utils.format,
    };
  },
  props: {
    selectedUser: {
      type: String,
      default: null,
    },
  },
  computed: {
    users() {
      let users = this.workdays
        .map((item) => item.user)
        .concat(this.oneTimePay.map((item) => item.user));
      if (users.length > 0) {
        return users.reduce((filter, current) => {
          var dk = filter.find((item) => item._id === current._id);
          if (!dk) {
            return filter.concat([current]);
          } else {
            return filter;
          }
        }, []);
      }
      return users;
    },
    unsignedTotal() {
      return this.workdays.filter((workday) => workday.state == "created")
        .length;
    },
    hoursTotal() {
      let workdays = this.workdays.filter((item) => item.state == "signed");

      workdays = this.filterSelection(workdays);

      return utils.finance.workdayHours(workdays);
    },
    revenueTotal() {
      // catch workdays
      let workdays = this.workdays;

      // apply filters
      workdays = workdays.filter((item) => item.state == "signed");

      // catch oneTimePay

      let oneTimePay = this.oneTimePay;

      // apply filters
      oneTimePay = oneTimePay.filter((item) => item.state == "signed");

      workdays = this.filterSelection(workdays);
      oneTimePay = this.filterSelection(oneTimePay);

      return utils.finance.revenue(workdays, oneTimePay);
    },
    paidTotal() {
      // catch oneTimePay
      let oneTimePay = this.oneTimePay;

      // apply filters
      oneTimePay = oneTimePay.filter((item) => item.state == "signed");

      oneTimePay = this.filterSelection(oneTimePay);

      return utils.finance.advance(oneTimePay);
    },
    balance() {
      return this.revenueTotal - this.paidTotal;
    },
  },
  methods: {
    ...mapActions(["getWorkdays", "getOneTimePay"]),
    filterSelection(array) {
      let result = array;
      if (this.selectedUser) {
        if (this.selectedUser == "crew") {
          result = result.filter((item) => {
            return item.user.accessLevel < 10;
          });
        } else {
          result = result.filter((item) => item.user._id == this.selectedUser);
        }
      }

      return result;
    },
    refresh() {
      this.getWorkdays()
        .then((workdays) => {
          this.workdays = workdays;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getOneTimePay()
        .then((oneTimePay) => {
          this.oneTimePay = oneTimePay;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
label,
input {
  align-self: center;
}
</style>
