<template>
  <div class="v-stack h-stretch gap-3">
    <div class="h-stack h-center gap-3">
      <button @click="$router.push('/users/bonus/new/' + userId)" class="add">
        Add Bonus
      </button>
      <button @click="$router.push('/users/advance/new/' + userId)" class="add">
        Add Advance
      </button>
      <button class="add" @click="$router.push('/users/workday/new/' + userId)">
        Add Workday
      </button>
    </div>
    <HoursOverview :selectedUser="userId"></HoursOverview>
    <div class="h-stack h-start gap-3">
      <label>Period</label>
      <select v-model="period">
        <option value="all">Eternity</option>
        <option value="year">Year</option>
        <option value="month">Month</option>
        <option value="range">Range</option>
      </select>
      <template v-if="period == 'year' || period == 'month'">
        <label>Year: </label>
        <select v-model="year">
          <option v-for="year in years" :key="year" :value="year">
            {{ year }}
          </option>
        </select>
      </template>
      <template v-if="period == 'month'">
        <label>Month: </label>
        <select v-model="month">
          <option v-for="i in 12" :key="i" :value="i">{{ i }}</option>
        </select>
      </template>
      <template v-if="period == 'range'">
        <label>Start: </label>
        <InputDatePicker v-model="startDate"></InputDatePicker>
        <label>End: </label>
        <InputDatePicker v-model="endDate"></InputDatePicker>
      </template>
    </div>
    <Hours
      :selectedUser="userId"
      :workdays="workdaysfiltered"
      :oneTimePay="oneTimePayFiltered"
      v-on:refresh="refresh()"
    ></Hours>
  </div>
</template>

<script>
import Hours from "@/components/Hours.vue";
import HoursOverview from "@/components/HoursOverview.vue";
import moment from "moment";
import { mapActions } from "vuex";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  data() {
    return {
      workdays: [],
      oneTimePay: [],
      period: "month",
      year: moment().year(),
      month: moment().month() + 1,
      startDate: moment().date(1).toISOString(),
      endDate: moment().date(moment().daysInMonth()).toISOString(),
    };
  },
  props: ["id"],
  components: {
    Hours,
    HoursOverview,
    InputDatePicker,
  },
  computed: {
    years() {
      const oneTimePayYears = this.oneTimePay.reduce((a, b) => {
        return a.concat([moment(b.date).year()]);
      }, []);

      const workdaysYears = this.workdays.reduce((a, b) => {
        return a.concat([moment(b.day).year()]);
      }, []);

      return Array.from(new Set(oneTimePayYears.concat(workdaysYears))).sort();
    },
    workdaysfiltered() {
      return this.workdays.filter((workday) => this.filterDate(workday.day));
    },
    oneTimePayFiltered() {
      return this.oneTimePay.filter((oneTimePay) =>
        this.filterDate(oneTimePay.date)
      );
    },
    userId() {
      return this.id || this.$store.state.id;
    },
  },
  methods: {
    ...mapActions(["getWorkdays", "getOneTimePay"]),
    filterDate(date) {
      const dateObject = moment(date);
      if (this.period == "year") {
        return dateObject.year() == this.year;
      } else if (this.period == "month") {
        return (
          dateObject.year() == this.year && dateObject.month() + 1 == this.month
        );
      } else if (this.period == "range") {
        return dateObject.isBetween(this.startDate, this.endDate, "day", "[]");
      } else if (this.period == "all") {
        return true;
      }
      return false;
    },
    refresh() {
      this.getWorkdays()
        .then((workdays) => {
          this.workdays = workdays;
        })
        .catch((error) => {
          console.log(error);
        });

      this.getOneTimePay()
        .then((oneTimePay) => {
          this.oneTimePay = oneTimePay;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style scoped>
.h-stack div {
  align-self: center;
}

.h-stack label {
  align-self: center;
}

.h-stack select {
  align-self: center;
  width: 160px;
}
</style>
